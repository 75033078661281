import { Component, OnInit } from '@angular/core';
import { EChartOption } from 'echarts';
import { echartStyles } from '../../shared/echart-styles';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NavigationService } from '../../shared/services/navigation.service';
import { AuthService } from '../../shared/services/auth.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { LocationStrategy } from '@angular/common';
import { NgbModal, NgbDate, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isSuccess } from 'angular-in-memory-web-api';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    chartLineOption1: EChartOption;
    chartLineOption2: EChartOption;
    chartLineOption3: EChartOption;
    salesChartBar: EChartOption;
    salesChartPie: EChartOption;

    Subscriptions_id = [];
    Subscriptions = [];
    boxList = [];
    selectCheckBox = [];
    public payPalConfig?: IPayPalConfig;
    public showSuccess: boolean = false;
    public showCancel: boolean = false;
    public showError: boolean = false;
    existing: boolean = true;
    newOrder: boolean = true;
    view: any;
    viewTub: any;
    modalOption: NgbModalOptions = {};
    role: any = localStorage.getItem('roleId');
    role1: any = localStorage.getItem('name');
    in_warehouse: any;
    with_user: any;
    orders: any;
    boxes: any;
    totalboxes: any;
    show: Boolean = false;
    plan: Boolean = false;
    selectPlan: any;
    reselectPlan = [];
    order_id: any;
    renewPlan = [];
    login: { [index: string]: any } = {};
    ngForm: any;
    number = [];
    boxcount: any;
    constructor(private location: LocationStrategy, private router: Router, private navigationService: NavigationService, private auth: AuthService, private modalService: NgbModal) {

        this.auth.getCommanData('', localStorage.getItem("name") + '/dashboard').subscribe(success => {
            console.log(success.data);
            this.in_warehouse = success.data.counts.count_in_warehouse;
            this.orders = success.data.counts.count_orders;
            this.with_user = success.data.counts.count_with_user;
            this.boxes = success.data.counts.count_boxes;
            console.log(this.boxes);
        });
        if (localStorage.getItem('token') == '' && localStorage.getItem('name') == 'user') {
            this.router.navigateByUrl('/');
        } else if (localStorage.getItem('token') == '' && localStorage.getItem('name') == 'admin') {
            this.router.navigateByUrl('/admin');
        }
        if (localStorage.getItem('payments') == '') {
            this.auth.getCommanData({ userId: localStorage.getItem('userId') }, 'userDetail/' + localStorage.getItem('userId')).subscribe(success => {
                console.log(success);
                if (success.data.payments == '') {
                    localStorage.setItem('payments', success.data.payments);
                    localStorage.setItem('selectPlan', success.data.select_plan);
                }
            });
        }
        if (localStorage.getItem('payments') != '' && localStorage.getItem('loginClick') == '') {
            this.router.navigateByUrl('subscription-select');
        }
        this.auth.getDataList({ userId: localStorage.getItem('userId') }, 'planExpir').subscribe(success => {
            console.log(success);
            if (success.data != '') {
                this.plan = true;
            } else {
                this.plan = false;
            }

            this.renewPlan = success.data;
            // this.initConfig(success.data);
        });
        // window.history.go(-1);
        this.auth.getuser('profile').subscribe(success => {
            console.log(success.data);
        });
        localStorage.setItem("orderId", "");
    }
    ngAfterViewInit() {
        setTimeout(() => {
            $(".postcode .ui-autocomplete-dropdown .ui-button-icon-left").html('<i class="fa fa-caret-down dropbtn" aria-hidden="true"></i>');
        }, 500);

    }
    ngOnInit() {
        localStorage.setItem('showboxes','');
        localStorage.setItem("orderId", "");

        console.log(localStorage.getItem('name'));
        this.navigationService.publishNavigationChange(localStorage.getItem('name'));
        this.chartLineOption1 = {
            ...echartStyles.lineFullWidth, ...{
                series: [{
                    data: [30, 40, 20, 50, 40, 80, 90],
                    ...echartStyles.smoothLine,
                    markArea: {
                        label: {
                            show: true
                        }
                    },
                    areaStyle: {
                        color: 'rgba(102, 51, 153, .2)',
                        origin: 'start'
                    },
                    lineStyle: {
                        color: '#663399',
                    },
                    itemStyle: {
                        color: '#663399'
                    }
                }]
            }
        };
        this.chartLineOption2 = {
            ...echartStyles.lineFullWidth, ...{
                series: [{
                    data: [30, 10, 40, 10, 40, 20, 90],
                    ...echartStyles.smoothLine,
                    markArea: {
                        label: {
                            show: true
                        }
                    },
                    areaStyle: {
                        color: 'rgba(255, 193, 7, 0.2)',
                        origin: 'start'
                    },
                    lineStyle: {
                        color: '#FFC107'
                    },
                    itemStyle: {
                        color: '#FFC107'
                    }
                }]
            }
        };
        this.chartLineOption2.xAxis.data = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        this.chartLineOption3 = {
            ...echartStyles.lineNoAxis, ...{
                series: [{
                    data: [40, 80, 20, 90, 30, 80, 40, 90, 20, 80, 30, 45, 50, 110, 90, 145, 120, 135, 120, 140],
                    lineStyle: {
                        color: 'rgba(102, 51, 153, 0.86)',
                        width: 3,
                        ...echartStyles.lineShadow
                    },
                    label: { show: true, color: '#212121' },
                    type: 'line',
                    smooth: true,
                    itemStyle: {
                        borderColor: 'rgba(102, 51, 153, 1)'
                    }
                }]
            }
        };
        // this.chartLineOption3.xAxis.data = ['1', '2', '3', 'Thu', 'Fri', 'Sat', 'Sun'];
        this.salesChartBar = {
            legend: {
                borderRadius: 0,
                orient: 'horizontal',
                x: 'right',
                data: ['Online']
            },
            grid: {
                left: '8px',
                right: '8px',
                bottom: '0',
                containLabel: true
            },
            tooltip: {
                show: true,
                backgroundColor: 'rgba(0, 0, 0, .8)'
            },
            xAxis: [{
                type: 'category',
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                axisTick: {
                    alignWithLabel: true
                },
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true
                }
            }],
            yAxis: [{
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: true,
                    interval: 'auto'
                }
            }

            ],

            series: [{
                name: 'Orders',
                data: [],
                label: { show: false, color: '#0168c1' },
                type: 'bar',
                barGap: 0,
                color: '#bcbbdd',
                smooth: true,

            },
                // {
                //     name: 'Offline',
                //     data: [45000, 82000, 35000, 93000, 71000, 89000, 49000, 91000, 80200, 86000, 35000, 40050],
                //     label: { show: false, color: '#639' },
                //     type: 'bar',
                //     color: '#7569b3',
                //     smooth: true
                // }

            ]
        };

        this.salesChartPie = {
            color: ['#62549c', '#7566b5', '#7d6cbb', '#8877bd', '#9181bd', '#6957af'],
            tooltip: {
                show: true,
                backgroundColor: 'rgba(0, 0, 0, .8)'
            },

            xAxis: [{
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            }

            ],
            yAxis: [{
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            }
            ],
            series: [{
                name: 'Sales by Country',
                type: 'pie',
                radius: '75%',
                center: ['50%', '50%'],
                data: [
                    { value: 535, name: 'USA' },
                    { value: 310, name: 'Brazil' },
                    { value: 234, name: 'France' },
                    { value: 155, name: 'Germany' },
                    { value: 130, name: 'UK' },
                    { value: 348, name: 'India' }
                ],
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
            ]
        };

        this.auth.getDataList('', 'chartHistory').subscribe(success => {
            console.log(success.data);
            console.log(this.salesChartBar.series[0]);
            this.show = true;
            this.salesChartBar.series[0].data = success.data;
        });
        this.auth.getCommanData('', 'subscriptions').subscribe(success => {
            // console.log(success);
            this.Subscriptions = success.data;
        });
    }
    search(event, field) {
        console.log()
        if (field == 'subscription_id') {
            $(".subscription input").removeClass("validationError");
            // this.subscription = false;
            this.Subscriptions = this.Subscriptions.map(o => {
                return o;
            });
        }
        if (field == 'number') {
            this.number = [
                { id: 1, name: "1" },
                { id: 2, name: "2" },
                { id: 3, name: "3" },
                { id: 4, name: "4" },
                { id: 5, name: "5" },
                { id: 6, name: "6" },
                { id: 7, name: "7" },
                { id: 8, name: "8" },
                { id: 9, name: "9" },
                { id: 10, name: "10" },
                { id: 11, name: "11" },
                { id: 12, name: "12" },
                { id: 13, name: "13" },
                { id: 14, name: "14" },
                { id: 15, name: "15" },
                { id: 16, name: "16" },
                { id: 17, name: "17" },
                { id: 18, name: "18" },
                { id: 19, name: "19" },
                { id: 20, name: "20" },
                { id: 21, name: "21" },
                { id: 22, name: "22" },
                { id: 23, name: "23" },
                { id: 24, name: "24" },
                { id: 25, name: "25" },
                { id: 26, name: "26" },
                { id: 27, name: "27" },
                { id: 28, name: "28" },
                { id: 29, name: "29" },
                { id: 30, name: "30" },
                { id: 31, name: "31" },
                { id: 32, name: "32" },
                { id: 33, name: "33" },
                { id: 34, name: "34" },
                { id: 35, name: "35" },
                { id: 36, name: "36" },
                { id: 37, name: "37" },
                { id: 38, name: "38" },
                { id: 39, name: "39" },
                { id: 40, name: "40" },
                { id: 41, name: "41" },
                { id: 42, name: "42" },
                { id: 43, name: "43" },
                { id: 44, name: "44" },
                { id: 45, name: "45" },
                { id: 46, name: "46" },
                { id: 47, name: "47" },
                { id: 48, name: "48" },
                { id: 49, name: "49" },
                { id: 50, name: "50" },
            ];
        }
    }
    select(content, data) {
        console.log(this.view);
        this.view = 2;
        this.reselectPlan = data;
        this.boxcount = data.total_box;
        this.reselectPlan['userId'] = localStorage.getItem('userId');
        // this.initConfig(data['total_charge']);
        this.modalOption.size = 'sm';
        this.modalService.open(content, this.modalOption)
            .result.then((result) => {
                // console.log(result);
            }, (reason) => {
                console.log('Err!', reason);
            });

    }
    private initConfig(price: string): void {
        let custPrice = document.getElementById('defaultPrice');
        console.log(custPrice);
        this.payPalConfig = {
            currency: 'EUR',
            // clientId: 'AUI4SVAS3ul0DwbX8rxgONIeDsBYkXl_V8MPlP_6kvGil6ULHaJoTIxtRNZYRkINTp5PouoTd25dCt0j',
            clientId: 'AWyXQo3RGX6xLuLcNKkQ-xvmXi62UKlSOzJjAcMo8EfaIN_LmI_U_zpudwReDihLCg-yAq2NpBU6jw-y',
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'CAPTURE',
                purchase_units: [
                    {
                        amount: {
                            currency_code: 'EUR',
                            value: price,
                            breakdown: {
                                item_total: {
                                    currency_code: 'EUR',
                                    value: price
                                }
                            }
                        },
                        items: [
                            {
                                name: 'Enterprise Subscription',
                                quantity: '1',
                                category: 'DIGITAL_GOODS',
                                unit_amount: {
                                    currency_code: 'EUR',
                                    value: price,
                                },
                            }
                        ]
                    }
                ]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                label: 'paypal',
                layout: 'vertical'
            },
            onApprove: (data, actions) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then((details: any) => {
                    console.log('onApprove - you can get full order details inside onApprove: ', details);
                });

            },
            onClientAuthorization: (data) => {
                console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
                this.showSuccess = true;
                this.modalService.dismissAll();
                this.reselectPlan['newOrder'] = this.newOrder;
                this.auth.updateData(this.reselectPlan, 'user/renewPlan').subscribe(success => {
                    console.log(success);
                    this.auth.signin({ data: data, userId: localStorage.getItem('userId'), new: this.newOrder, order_id: this.order_id }, 'payments').subscribe(success => {
                        console.log(success);
                    });
                    this.auth.getDataList({ userId: localStorage.getItem('userId') }, 'planExpir').subscribe(success => {
                        console.log(success);
                        if (!success.data) {
                            this.plan = false;
                        } else {
                            this.plan = true;
                        }
                        this.renewPlan = success.data;
                    });
                });
            },
            onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);
                this.showCancel = true;

            },
            onError: err => {
                console.log('OnError', err);
                this.showError = true;
            },
            onClick: (data, actions) => {
                console.log('onClick', data, actions);
                this.resetStatus();
            },
            onInit: (data, actions) => {
                console.log('onInit', data, actions);
            }
        };
    }
    private resetStatus(): void {
        this.showError = false;
        this.showSuccess = false;
        this.showCancel = false;
    }
    changePlan(data) {
        console.log(data);
        this.selectPlan = data;
    }
    planchange(data) {
        console.log(this.view);
        this.order_id = this.reselectPlan['order_id'];
        this.totalboxes = this.reselectPlan['total_box'];
        if (data == "yes") {
            this.view = 0;
            var total = this.reselectPlan['duration_months'] * this.reselectPlan['total_box'] * this.reselectPlan['per_month_charge'];
            this.initConfig(total.toString());
        } else {
            this.view = 1;
            setTimeout(() => {
                $(".renew .ui-autocomplete-dropdown .ui-button-icon-left").html('<i class="fa fa-caret-down dropbtn" aria-hidden="true"></i>');
            }, 100);
        }
    }
    selectRenewPlan(content, data) {
        console.log(data);
        this.reselectPlan = data;
        this.viewTub = 2;
        this.modalService.dismissAll();
        this.modalOption.size = "sm";
        this.modalService.open(content, this.modalOption)
            .result.then((result) => {
                // console.log(result);
            }, (reason) => {
                console.log('Err!', reason);
            });
    }
    tubchange(data) {
        console.log(data);
        if (data == 'yes') {
            this.viewTub = 0;
            this.totalboxes = this.boxcount;
            var total = this.reselectPlan['duration_months'] * this.totalboxes * this.reselectPlan['per_month_charge'];
            this.initConfig(total.toString());
        } else {
            setTimeout(() => {
                $(".number .ui-autocomplete-dropdown .ui-button-icon-left").html('<i class="fa fa-caret-down dropbtn" aria-hidden="true"></i>');
            }, 100);
            this.viewTub = 1;
            this.existing = true;
            this.newOrder = true;
            this.totalboxes = '';
            this.selectCheckBox = [];
            this.reselectPlan['order_id'] = this.order_id;
            this.auth.getDataList({ order_id: this.order_id, user_id: localStorage.getItem('userId') }, 'getBoxList').subscribe(success => {
                console.log(success);
                this.boxList = success.data;
            });
        }
    }
    notallowzero(data) {
        console.log(data.target.name);
        var val = Number($("#" + data.target.name).val()).toString();
        while (val.substring(0, 1) === '0') {
            val = val.substring(1);
        }
        $("#" + data.target.name).val(val);

    }
    changeTub(data) {
        setTimeout(() => {
            var recode = data.id;
            console.log(recode);
            if (recode == '') {
                this.existing = true;
            } else {
                this.existing = false;
            }
            this.totalboxes = recode;
            this.reselectPlan['total_box'] = this.totalboxes;
            this.reselectPlan['user_id'] = localStorage.getItem("userId");
            this.reselectPlan['order_id'] = this.order_id;
            var total = this.reselectPlan['duration_months'] * this.totalboxes * this.reselectPlan['per_month_charge'];
            this.reselectPlan['total'] = total;
            this.initConfig(total.toString());
        }, 200);


        console.log(data);
    }
    existingTub(data, flag) {
        // console.log(flag);
        if (flag) {
            this.selectCheckBox.push(data);
            this.selectCheckBox.sort();
            this.newOrder = false;
            this.totalboxes = this.selectCheckBox.length;
            var total = this.reselectPlan['duration_months'] * this.totalboxes * this.reselectPlan['per_month_charge'];
            this.initConfig(total.toString());
        } else {
            const index: number = this.selectCheckBox.indexOf(data);
            this.selectCheckBox.splice(index, 1);
            if (this.selectCheckBox.length > 0) {
                this.totalboxes = this.selectCheckBox.length;
                var total = this.reselectPlan['duration_months'] * this.totalboxes * this.reselectPlan['per_month_charge'];
                this.initConfig(total.toString());
            } else {
                setTimeout(() => {
                    $(".number .ui-autocomplete-dropdown .ui-button-icon-left").html('<i class="fa fa-caret-down dropbtn" aria-hidden="true"></i>');
                }, 100);
                this.totalboxes = this.selectCheckBox.length;
                this.newOrder = true;
            }

        }
        this.reselectPlan['exiting_boxes'] = this.selectCheckBox;
        console.log(this.selectCheckBox);
    }
    saveChanges() {
        if (this.selectCheckBox.length > 0) {
            console.log(this.selectCheckBox);
        } else {
            console.log("n");
        }
    }
}
