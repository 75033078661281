import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bootDash';
  ngOnInit() {

    let value = localStorage.getItem('token');
    if (value == undefined) {
      localStorage.setItem('token', '');
      localStorage.setItem('name', 'user');
    }
  }
}
