import { Injectable } from '@angular/core';
// import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { tap, map, filter, catchError } from 'rxjs/operators';
import { HttpClient, HttpRequest, HttpResponse, HttpEvent, HttpEventType, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Configuration } from '../app-constants';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private headers: any;
  private serverUrl: string;

  constructor(
    private _configuration: Configuration,
    private http: HttpClient,
    private router: Router) {

    this.serverUrl = _configuration.serverUrl
  }


  public getProducts() {
    // return this.httpClient.get(this.SERVER_URL);
    return {
      'data': [{
        "name": "Ethel Price",
        "gender": "female",
        "company": "Johnson",
        "age": 22
      },
      {
        "name": "Wong Craft",
        "gender": "male",
        "company": "Opticall"
      },
      {
        "name": "Merritt Cole",
        "gender": "male",
        "company": "Techtrix"
      },
      {
        "name": "Wilder Gonzales",
        "gender": "male",
        "company": "Geekko"
      },
      {
        "name": "Georgina Schultz",
        "gender": "female",
        "company": "Suretech"
      },
      {
        "name": "Carroll Buchanan",
        "gender": "male",
        "company": "Ecosys"
      },
      {
        "name": "Valarie Atkinson",
        "gender": "female",
        "company": "Hopeli"
      },
      {
        "name": "Schroeder Mathews",
        "gender": "male",
        "company": "Polarium"
      },
      {
        "name": "Lynda Mendoza",
        "gender": "female",
        "company": "Dogspa"
      },
      {
        "name": "Sarah Massey",
        "gender": "female",
        "company": "Bisba"
      },
      {
        "name": "Robles Boyle",
        "gender": "male",
        "company": "Comtract"
      },
      {
        "name": "Evans Hickman",
        "gender": "male",
        "company": "Parleynet"
      },
      {
        "name": "Dawson Barber",
        "gender": "male",
        "company": "Dymi"
      },
      {
        "name": "Bruce Strong",
        "gender": "male",
        "company": "Xyqag"
      },],
      'page': {
        // The number of elements in the page
        size: 5,
        // The total number of elements
        totalElements: 15,
        // The total number of pages
        totalPages: 3,
        // The current page number
        pageNumber: 0

      },
    };
  }

  getListData(url, page) {
    return this.http.post(this.serverUrl + url, JSON.stringify(page), { headers: this.headers }).pipe(
      map(res => {
        return res;
      }));
  }

  // storeData(url, data) {
  //   return this.http.post(this.serverUrl + url, JSON.stringify(data), { headers: this.headers }).pipe(
  //     map(res => {
  //       return res.json();
  //     }));
  // }

  private handleError(err: Response) {
    if (err.status === 401 || err.status === 403) {
      localStorage.clear();
      this.router.navigateByUrl(`/login`);
      return Observable.throw(err.statusText);
    }
  }

  public loginUser<T>(apiUrl: any, data: any): Observable<T> {
    // let _body = 'email=' +data.email+ '&password=' +data.password;
    let _body = JSON.stringify({
      email: data.email,
      password: data.password,
      userData: data.userData
    })
    let _options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<T>(this.serverUrl, _body, _options);
  }

  public forgotApis<T>(apiUrl: any, data: any): Observable<T> {
    // let _body = 'email=' +data.email+ '&password=' +data.password;
    let _body = JSON.stringify({
      email: data
    })
    let _options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<T>(this.serverUrl + apiUrl, _body, _options);
  }

  public checkResetToken<T>(apiUrl: any, data: any): Observable<T> {
    // let _body = 'email=' +data.email+ '&password=' +data.password;
    let _body = JSON.stringify({
      reset_token: data
    })
    let _options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<T>(this.serverUrl + apiUrl, _body, _options);
  }

  public resetPassword<T>(apiUrl: any, data: any): Observable<T> {
    // let _body = 'email=' +data.email+ '&password=' +data.password;
    let _body = JSON.stringify({
      reset_token: data.reset_token,
      new_password: data.new_password,
      new_password_confirmation: data.new_password_confirmation
    })
    let _options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<T>(this.serverUrl + apiUrl, _body, _options);
  }

  public getAll<T>(apiUrl: any): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      )
    };
    return this.http.get<T>(this.serverUrl + apiUrl, _options);//.catch(this.handleError);
  }

  public getAllOutside<T>(apiUrl: any): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        }
      )
    };
    return this.http.get<T>(apiUrl, _options);//.catch(this.handleError);
  }

  public postCall<T>(apiUrl: any, data: any): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      )
    };

    return this.http.post<T>(this.serverUrl + apiUrl, JSON.stringify(data), _options);
  }


  public getSingle<T>(apiUrl: any, param: any): Observable<T> {
    return this.http.get<T>(this.serverUrl + apiUrl + '/' + param);
  }

  public getMultipleId<T>(apiUrl: any, param: any): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      )
    };
    return this.http.get<T>(this.serverUrl + apiUrl + '/' + param, _options);
  }

  public getbyString<T>(apiUrl: any, param: string): Observable<T> {
    return this.http.get<T>(this.serverUrl + apiUrl + "?version=" + param);
  }

  public getbyEmail<T>(apiUrl: any, param: string): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      )
    };
    return this.http.get<T>(this.serverUrl + apiUrl + "?Email=" + param, _options);
  }

  public getbyNumber<T>(apiUrl: any, param: string): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      )
    };
    return this.http.get<T>(this.serverUrl + apiUrl + "?phoneNumber=" + param, _options);
  }

  public getbycode<T>(apiUrl: any, param: string): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      )
    };
    return this.http.get<T>(this.serverUrl + apiUrl + "?code=" + param, _options);
  }

  public getbyPageNumber<T>(apiUrl: any, param: number): Observable<T> {
    let _options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      )
    };
    return this.http.get<T>(this.serverUrl + apiUrl + "?PageNumber=" + param, _options);
  }

  public storeData<T>(apiUrl: any, data: any): Observable<T> {
    return this.http.post<T>(this.serverUrl + apiUrl, JSON.stringify(data));
  }

  public updateData<T>(apiUrl: any, param: any, itemToUpdate: any): Observable<T> {
    return this.http
      .put<T>(this.serverUrl + apiUrl + '/' + param, JSON.stringify(itemToUpdate));
  }

  public deleteData<T>(apiUrl: any, param: any): Observable<T> {
    return this.http.delete<T>(this.serverUrl + apiUrl + '/' + param);
  }
}


// @Injectable()
// export class CustomInterceptor implements HttpInterceptor {
//   token: string;
//   apiService: object;
//   public _usermodel: UserLoginVM = new UserLoginVM();
//   constructor(private router: Router, private _apiservice: ApiService) {
//     this.token = localStorage.getItem('access_token');
//     this._usermodel.password = 'nopass';
//     this._usermodel.email = 'afraz@gmail.com';
//   }

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//     if (req.headers.get('Authorization') == null) {
//       if (this.token) {
//         req = req.clone({
//           setHeaders: {
//             'access_token': this.token
//           }
//         });
//       }
//       return next.handle(req).pipe(
//         catchError(this.handleError)
//       )
//     } else {
//       return next.handle(req).pipe(
//         catchError(this.handleError)
//       );
//     }
//   }

//   private handleError(err: HttpErrorResponse, req): Observable<any> {

//     if (err.status === 401 || err.status === 403) {
//       return this._apiservice.loginUser<any>("token", this._usermodel).pipe(flatMap(
//         (data: any) => {
//           if (data.status == 200) {
//             localStorage.setItem("access_token", data.result.token);
//             req = req.clone({
//               setHeaders: {
//                 'access_token': data.result.token
//               }
//             });
//           } else {
//             localStorage.clear();
//             this.router.navigateByUrl(`/login`);
//           }
//           return req;
//         }))
//       return throwError(err);
//     }

//   }

// }
