import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { tap, map, filter } from 'rxjs/operators';
import { Http, Headers } from '@angular/http';
import { Configuration } from '../app-constants';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse
} from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = true;
  token = localStorage.getItem('token');
  private headers: any;
  private serverUrl = this.appConfigs.serverUrl;
  constructor(private store: LocalStoreService, private router: Router, public http: Http, public appConfigs: Configuration, public httpClient: HttpClient) {
    this.setHeaders();
  }

  setHeaders() {

    this.headers = new Headers({
      'Content-Type': 'application/json,application/x-www-form-urlencoded; charset=UTF-8,application/octet-stream',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  getuser(url) {
    this.setHeaders();
    return this.http.get(this.serverUrl + localStorage.getItem('name') + '/' + url, { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }

  signin(credentials, url) {
    this.authenticated = true;
    this.setHeaders();
    return this.http.post(this.serverUrl + localStorage.getItem('name') + '/' + url, JSON.stringify(credentials), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  signout(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + localStorage.getItem('name') + '/' + url, JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  getDataList(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + localStorage.getItem('name') + '/' + url, JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  getCommanData(data, url) {
    this.setHeaders();
    return this.http.get(this.serverUrl + url, { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
 
  signup(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + url, JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  storeData(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + url + '/create', JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  editData(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + url + '/edit', JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  updateData(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + url + '/update', JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  deleteData(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + url + '/destroy', JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  profile(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + localStorage.getItem('name') + '/' + url, JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
  changeStatus(data, url) {
    this.setHeaders();
    return this.http.post(this.serverUrl + url , JSON.stringify(data), { headers: this.headers }).pipe(
      map(res => {
        //this.progressService.done();
        return res.json();
      }));
  }
}
