import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import {Configuration } from '../../../../app-constants';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

  notifications: any[];
  user_name = localStorage.getItem('user_name');
  imageName = localStorage.getItem('image');
  profileUrl:any;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private router: Router,
    public configuration:Configuration,
    private toastr: ToastrService,
  ) {
    this.notifications = [
      {
        icon: 'i-Speach-Bubble-6',
        title: 'New message',
        badge: '3',
        text: 'James: Hey! are you busy?',
        time: new Date(),
        status: 'primary',
        link: '/chat'
      },
      {
        icon: 'i-Receipt-3',
        title: 'New order received',
        badge: '$4036',
        text: '1 Headphone, 3 iPhone x',
        time: new Date('11/11/2018'),
        status: 'success',
        link: '/tables/full'
      },
      {
        icon: 'i-Empty-Box',
        title: 'Product out of stock',
        text: 'Headphone E67, R98, XL90, Q77',
        time: new Date('11/10/2018'),
        status: 'danger',
        link: '/tables/list'
      },
      {
        icon: 'i-Data-Power',
        title: 'Server up!',
        text: 'Server rebooted successfully',
        time: new Date('11/08/2018'),
        status: 'success',
        link: '/dashboard/v2'
      },
      {
        icon: 'i-Data-Block',
        title: 'Server down!',
        badge: 'Resolved',
        text: 'Region 1: Server crashed!',
        time: new Date('11/06/2018'),
        status: 'danger',
        link: '/dashboard/v3'
      }
    ];
  }

  ngOnInit() {
    console.log(this.imageName);
    this.profileUrl = this.configuration.uploadUrl+'customers/';
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
      setTimeout(() => {
        // state.childnavOpen = true;
      }, 50);
    }
  }
  redirect(){
    if(localStorage.getItem('userId') != ''){
      this.router.navigateByUrl("dashboard");
    }else{
      if(localStorage.getItem('name') == 'admin'){
        this.router.navigateByUrl("admin");
      }else{
        this.router.navigateByUrl("/");
      }
      
    }
  }
  signout() {
    this.auth.signout('', 'logout')
      .subscribe((success) => {

        this.toastr.success('Logout Successfully', 'Success!', { progressBar: true });

      }, (error) => {
      });

    localStorage.setItem('userId', '');
    localStorage.setItem('user', '');
    localStorage.setItem('token', '');

    let that = this;
    let role = localStorage.getItem('role');

    localStorage.setItem('access_code', '');
    localStorage.setItem('roles', JSON.stringify({}));

    localStorage.setItem('role', role);
    localStorage.setItem('client_code', '');
    localStorage.setItem('standalone', undefined);
    if (localStorage.getItem('name') == 'user') {
      setTimeout(() => {
        // window.location.href = "/#/login";
        this.router.navigateByUrl("login");
      }, 3000);

    } else {
      setTimeout(() => {
        this.router.navigateByUrl("/admin");
      }, 3000);
    }

  }

}
