import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor() { }

  public statusList = [
    { 'id': 1, 'name': 'Active', },
    { 'id': 2, 'name': 'Inactive', },
  ]

  getStatusName = (status) => {
    return this.statusList[status];
  }

  statusTag = () => {
    return {
      '0': { 'className': '', 'display': 'brightness_1', 'tooltip': 'Open' },
      '1': { 'className': '', 'display': 'brightness_1', 'tooltip': 'Cancelled' },
      '2': { 'className': '', 'display': 'brightness_1', 'tooltip': 'Converted' },
      '3': { 'className': '', 'display': 'brightness_1', 'tooltip': 'Fail' },
    }
  };

}
