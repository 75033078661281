import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteModule } from 'primeng/primeng';
import { ApiService } from './shared/services/api.service';
import { DatatableService } from './shared/services/datatable.service';
import { HelpersService } from './shared/services/helpers.service';
import { Configuration } from './shared/app-constants';
import { PaginationModule } from "ngx-bootstrap-pagination"
import { NgxPaginationModule } from 'ngx-pagination';
import { NgBusyModule } from 'ng-busy';
import { Daterangepicker } from 'ng2-daterangepicker';
import {DpDatePickerModule} from 'ng2-date-picker';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FileDroppa } from 'file-droppa';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LightboxModule } from 'ngx-lightbox';
import { NgxPayPalModule } from 'ngx-paypal';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    
   
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,
    CommonModule,
    //PerfectScrollbarModule.forRoot(PERFECT_SCROLLBAR_CONFIG),\
    PerfectScrollbarModule,
    SharedComponentsModule,
    NgxEchartsModule,
    NgxDatatableModule,
    NgbModule,
    PaginationModule.forRoot(),
    NgxPaginationModule,
    NgBusyModule,
    Daterangepicker,
    DpDatePickerModule,
    NgxImageGalleryModule,
    AutoCompleteModule,
    NgxMaterialTimepickerModule,
    HttpModule,
    FileDroppa,
    DragDropModule,
    SweetAlert2Module.forRoot(),
    LightboxModule,
    AutoCompleteModule,
    NgxPayPalModule
  ],
  providers: [ApiService, DatatableService, HelpersService, Configuration],
  bootstrap: [AppComponent]
})
export class AppModule { }
