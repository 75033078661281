import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationService, IMenuItem, IChildItem } from '../../../../services/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Utils } from '../../../../utils';
import { Configuration } from '../../../../app-constants';

@Component({
	selector: 'app-sidebar-large',
	templateUrl: './sidebar-large.component.html',
	styleUrls: ['./sidebar-large.component.scss']
})
export class SidebarLargeComponent implements OnInit {

	selectedItem: IMenuItem;
	name: any;
	nav: IMenuItem[];
	userMenu: IMenuItem[] = [
		{
			name: 'Dashboard',
			description: 'Manage Dashboard.',
			type: 'link',
			icon: 'i-Bar-Chart',
			state: '/dashboard'
		},
		{
			name: 'Order Management',
			description: 'Manage Orders.',
			type: 'link',
			icon: 'i-Full-Cart',
			state: '/user-orders'
		},
		{
			name: 'Your Boxes',
			description: 'Manage Boxes.',
			type: 'link',
			icon: 'i-Windows-2',
			state: '/boxes'
		},
		{
			name: 'Media Gallery',
			description: 'View Media Gallery.',
			type: 'link',
			icon: 'i-Picasa',
			state: '/media'
		},
		{
			name: 'Profile',
			description: 'Manage Boxes.',
			type: 'link',
			icon: 'i-Administrator',
			state: '/profile'
		},
	];
	adminMenu: IMenuItem[] = [
		{
			name: 'Dashboard',
			description: 'Manage Dashboard.',
			type: 'link',
			icon: 'i-Bar-Chart',
			state: '/dashboard'
		},
		{
			name: 'Orders',
			description: 'Manage Orders.',
			type: 'link',
			icon: 'i-Full-Cart',
			state: '/orders'
		},
		{
			name: 'Box History',
			description: 'View Box History.',
			type: 'link',
			icon: 'i-Check',
			state: '/box-history'
		},
		{
			name: 'Boxes',
			description: 'Manage Boxes.',
			type: 'link',
			icon: 'i-Windows-2',
			state: '/admin-boxes'
		},
		{
			name: 'Box List',
			description: 'Manage Boxes.',
			type: 'link',
			icon: 'i-Check',
			state: '/boxes-list'
		},
		{
			name: 'Permissions',
			description: 'Manage Role Permission',
			type: 'link',
			icon: 'i-Key',
			state: '/permissions'
		},
		{
			name: 'Subscriptions',
			description: 'Manage Subscriptions.',
			type: 'link',
			icon: 'i-Money1',
			state: '/subscriptions'
		},
		{
			name: 'Employees',
			description: 'Manage Users.',
			type: 'link',
			icon: 'i-Business-Mens',
			state: '/users'
		},
		{
			name: 'Postcodes',
			description: 'Manage Postcodes.',
			type: 'link',
			icon: 'i-Opera',
			state: '/postcodes'
		},
		{
			name: 'Profile',
			description: 'Manage Boxes.',
			type: 'link',
			icon: 'i-Administrator',
			state: '/profile'
		},
		{
			name: 'Follow Up',
			description: 'Manage Follow Up.',
			type: 'link',
			icon: 'i-Repeat-2',
			state: '/follow_up'
		},
	];
	constructor(
		public router: Router,
		public navService: NavigationService,
		public configuration: Configuration
	) { }

	ngOnInit() {
		this.updateSidebar();
		// CLOSE SIDENAV ON ROUTE CHANGE
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((routeChange) => {
				this.closeChildNav();
				if (Utils.isMobile()) {
					this.navService.sidebarState.sidenavOpen = false;
				}
			});
		// this.setActiveFlag();
		// this.navService.menuItems$
		// 	.subscribe((items) => {
		// 		this.nav = items;
		// 		
		// 	});


		this.name = localStorage.getItem('name');
		if (localStorage.getItem('name') == 'user') {
			this.nav = this.userMenu;
			console.log (this.nav);
			let s = this.router.url;
			while (s.charAt(0) === '/') {
				s = s.substr(1);
			}
			console.log(s);
			setTimeout(() => {
				$("#"+s).addClass('active');
			}, 2000);
			
			// this.setActiveMainItem(s);
		} else {
			this.nav = this.adminMenu;
			let s = this.router.url;
			while (s.charAt(0) === '/') {
				s = s.substr(1);
			}
			console.log(s);
			// this.setActiveMainItem(s);
			setTimeout(() => {
				$("#"+s).addClass('active');
			}, 3000);
		}

	}

	selectItem(item) {
		this.navService.sidebarState.childnavOpen = true;
		this.selectedItem = item;
		this.setActiveMainItem(item);
	}
	closeChildNav() {
		this.navService.sidebarState.childnavOpen = false;
		this.setActiveFlag();
	}

	onClickChangeActiveFlag(item) {
		this.setActiveMainItem(item);
	}
	setActiveMainItem(item) {
		// item = parseInt(item);
		this.nav.forEach(item => {
			item.active = false;
		});
		item.active = true;
	}

	setActiveFlag() {
		if (window && window.location) {
			const activeRoute = window.location.hash || window.location.pathname;
			this.nav.forEach(item => {
				item.active = false;
				if (activeRoute.indexOf(item.state) !== -1) {
					this.selectedItem = item;
					item.active = true;
				}
				if (item.sub) {
					item.sub.forEach(subItem => {
						subItem.active = false;
						if (activeRoute.indexOf(subItem.state) !== -1) {
							this.selectedItem = item;
							item.active = true;
						}
						if (subItem.sub) {
							subItem.sub.forEach(subChildItem => {
								if (activeRoute.indexOf(subChildItem.state) !== -1) {
									this.selectedItem = item;
									item.active = true;
									subItem.active = true;
								}
							});
						}
					});
				}
			});
		}
	}

	updateSidebar() {
		if (Utils.isMobile()) {
			this.navService.sidebarState.sidenavOpen = false;
			this.navService.sidebarState.childnavOpen = false;
		} else {
			this.navService.sidebarState.sidenavOpen = true;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
	}

}
