import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
const adminRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'admin-boxes',
    loadChildren: () => import('./views/boxes/boxes.module').then(m => m.BoxesModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./views/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'user-orders',
    loadChildren: () => import('./views/user-order/user-order.module').then(m => m.UserOrderModule)
  },
  {
    path: 'media',
    loadChildren: () => import('./views/media/media.module').then(m => m.MediaModule)
  },
  {
    path: 'box-history',
    loadChildren: () => import('./views/box-history/box-history.module').then(m => m.BoxHistoryModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'permissions',
    loadChildren: () =>import('./views/permissions/permissions.module').then(m=> m.PermissionsModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./views/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
  },
  {
    path: 'postcodes',
    loadChildren: () => import('./views/postcodes/postcodes.module').then(m => m.PostcodesModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'follow_up',
    loadChildren: () => import('./views/follow-up/follow-up.module').then(m => m.FollowUpModule)
  },
  {
    path: 'boxes',
    loadChildren: () => import('./views/user-boxes/user-boxes.module').then(m => m.UserBoxesModule)
  },
  {
    path: 'boxes-list',
    loadChildren: () => import('./views/boxes-list/boxes-list.module').then(m => m.BoxesListModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./views/sub-history/sub-history.module').then(m => m.SubHistoryModule)
  }
];

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: 'admin',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
