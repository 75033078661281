import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getUrlName' })
export class getUrlName implements PipeTransform {
  transform(value: string) {
    
    while (value.charAt(0) === '/') {
        value = value.substr(1);
    }
    
    return value;
  }
}
