import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { NgTableComponent, NgTableFilteringDirective, NgTablePagingDirective, NgTableSortingDirective } from 'ng2-table/ng2-table';
import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';
import * as _ from "lodash";

@Injectable()
export class DatatableService {

  public rows: Array<any> = [];
  public columns: Array<any> = [];
  public FilterColumns: Array<any> = [];
  public page: number = 1;
  public itemsPerPage: number = 20;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public config: any = {};
  public serverUrl: any = '';

  private data: Array<any> = [];
  public sortDataTemp: any = {};

  public rows1: Array<any> = [];
  public columns1: Array<any> = [];
  public FilterColumns1: Array<any> = [];
  public page1: number = 1;
  public itemsPerPage1: number = 20;
  public maxSize1: number = 5;
  public numPages1: number = 1;
  public length1: number = 0;
  public config1: any = {};
  public serverUrl1: any = '';

  private data1: Array<any> = [];
  public sortDataTemp1: any = {};
  public rows2: Array<any> = [];
  public columns2: Array<any> = [];
  public FilterColumns2: Array<any> = [];
  public page2: number = 1;
  public itemsPerPage2: number = 20;
  public maxSize2: number = 5;
  public numPages2: number = 1;
  public length2: number = 0;
  public config2: any = {};
  public serverUrl2: any = '';

  private data2: Array<any> = [];
  public sortDataTemp2: any = {};

  constructor(private _apiService: ApiService) { }

  public setParam(componentData, key) {
    this[key] = componentData;
  }
  public setParam1(componentData1, key1) {
    this[key1] = componentData1;
  }
  public setParam2(componentData2, key2) {
    this[key2] = componentData2;
  }

  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }
  public changePage1(page: any, data: Array<any> = this.data1): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }
  public changePage2(page: any, data: Array<any> = this.data2): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }
    if (!columnName) {
      return data;
    }
  }
  public changeSort1(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config1.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }
  }
  public changeSort2(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config2.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }
  }
  public changeFilter(data: any, config: any): any {

    let tempFilteredData: { [index: string]: any } = {};
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        tempFilteredData[column.name] = column.filtering.filterString;
      }
    });

    if (!config.filtering) {
      return filteredData;
    }
    return tempFilteredData;
  }
  public changeFilter1(data: any, config: any): any {

    let tempFilteredData: { [index: string]: any } = {};
    let filteredData: Array<any> = data;
    this.columns1.forEach((column: any) => {
      if (column.filtering) {
        tempFilteredData[column.name] = column.filtering.filterString;
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    return tempFilteredData;
  }
  public changeFilter2(data: any, config: any): any {

    let tempFilteredData: { [index: string]: any } = {};
    let filteredData: Array<any> = data;
    this.columns2.forEach((column: any) => {
      if (column.filtering) {
        tempFilteredData[column.name] = column.filtering.filterString;
      }
    });
    if (!config.filtering) {
      return filteredData;
    }
    return tempFilteredData;
  }
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }, column: any = ''): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }
    let filteredData = this.changeFilter(this.data, this.config);

    let sortedData = this.changeSort(filteredData, this.config);
    if (page && config.paging) {
      if (page.page == 1) {
        let sortData = {};
        if (column && column.sort != '') {
          sortData[column.name] = column.sort;
        }
        _.forEach(this.config.sorting.columns, function (v, k) {
          if (v['sort'] != undefined && v['sort'] != false && column.name != v['name']) {
            sortData[v['name']] = v['sort'];
          }
        });
        this.sortDataTemp = sortData;
      }
      //console.log(page.page);
      let start = (page.page - 1) * page.itemsPerPage;
      return this._apiService.getListData(this.serverUrl, {
        'start': start,
        'itemsPerPage': page.itemsPerPage,
        'sort': this.config.sorting.columns[0],
        'sortNew': this.sortDataTemp,
        'FilterColumns': this.FilterColumns,
        'filtering': this.config.filtering,
        'FilterByColumns': filteredData,
        'where': this.config.where,
        'export': this.config.export
      }).pipe(
        map(res => res));
    } else {
      return sortedData;
    }
  }
  public onChangeTable1(config: any, page: any = { page: this.page1, itemsPerPage: this.itemsPerPage1 }, column: any = ''): any {

    if (config.filtering) {
      Object.assign(this.config1.filtering, config.filtering);
    }
    if (config.sorting) {
      Object.assign(this.config1.sorting, config.sorting);
    }
    let filteredData = this.changeFilter1(this.data1, this.config1);
    let sortedData = this.changeSort1(filteredData, this.config1);
    if (page && config.paging) {

      if (page.page == 1) {
        let sortData = {};
        if (column && column.sort != '') {
          sortData[column.name] = column.sort;
        }
        _.forEach(this.config1.sorting.columns, function (v, k) {
          if (v['sort'] != undefined && v['sort'] != false && column.name != v['name']) {
            sortData[v['name']] = v['sort'];
          }
        });
        this.sortDataTemp1 = sortData;
      }
      console.log(page.page);
      let start = (page.page - 1) * page.itemsPerPage;
      return this._apiService.getListData(this.serverUrl1, {
        'start': start,
        'itemsPerPage': page.itemsPerPage,
        'sort': this.config1.sorting.columns[0],
        'sortNew': this.sortDataTemp1,
        'FilterColumns': this.FilterColumns1,
        'filtering': this.config1.filtering,
        'FilterByColumns': filteredData,
        'where': this.config1.where,
        'export': this.config1.export
      }).pipe(
        map(res => res));
    } else {
      return sortedData;
    }
  }
  public onChangeTable2(config: any, page: any = { page: this.page2, itemsPerPage: this.itemsPerPage2 }, column: any = ''): any {

    if (config.filtering) {
      Object.assign(this.config2.filtering, config.filtering);
    }
    if (config.sorting) {
      Object.assign(this.config2.sorting, config.sorting);
    }
    let filteredData = this.changeFilter2(this.data2, this.config2);
    let sortedData = this.changeSort2(filteredData, this.config2);
    if (page && config.paging) {

      if (page.page == 1) {
        let sortData = {};
        if (column && column.sort != '') {
          sortData[column.name] = column.sort;
        }
        _.forEach(this.config2.sorting.columns, function (v, k) {
          if (v['sort'] != undefined && v['sort'] != false && column.name != v['name']) {
            sortData[v['name']] = v['sort'];
          }
        });
        this.sortDataTemp2 = sortData;
      }
      console.log(page.page);
      let start = (page.page - 1) * page.itemsPerPage;
      return this._apiService.getListData(this.serverUrl2, {
        'start': start,
        'itemsPerPage': page.itemsPerPage,
        'sort': this.config2.sorting.columns[0],
        'sortNew': this.sortDataTemp2,
        'FilterColumns': this.FilterColumns2,
        'filtering': this.config2.filtering,
        'FilterByColumns': filteredData,
        'where': this.config2.where,
        'export': this.config2.export
      }).pipe(
        map(res => res));
    } else {
      return sortedData;
    }
  }
}
