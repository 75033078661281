import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    constructor() {
    }
    name :any;
    adminMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: 'Manage Dashboard.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard'
        },
        {
            name: 'Orders',
            description: 'Manage Orders.',
            type: 'link',
            icon: 'i-Full-Cart',
            state: '/orders'
        },
        {
            name: 'Box History',
            description: 'View Box History.',
            type: 'link',
            icon: 'i-Check',
            state: '/box-history'
        },
        {
            name: 'Boxes',
            description: 'Manage Boxes.',
            type: 'link',
            icon: 'i-Windows-2',
            state: '/admin-boxes'
        },
        {
            name: 'Subscriptions',
            description: 'Manage Subscriptions.',
            type: 'link',
            icon: 'i-Money1',
            state: '/subscriptions'
        },
        {
            name: 'Users',
            description: 'Manage Users.',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/users'
        },
        {
            name: 'Postcodes',
            description: 'Manage Postcodes.',
            type: 'link',
            icon: 'i-Opera',
            state: '/postcodes'
        },
        {
            name: 'Profile',
            description: 'Manage Boxes.',
            type: 'link',
            icon: 'i-Administrator',
            state: '/profile'
        },
    ];

    defaultMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: 'Manage Dashboard.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard'
        },
    ]

    userMenu: IMenuItem[] = [
        {
            name: 'Dashboard',
            description: 'Manage Dashboard.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard'
        },
        {
            name: 'Order management',
            description: 'Manage Orders.',
            type: 'link',
            icon: 'i-Full-Cart',
            state: '/user-orders'
        },
        {
            name: 'Your Boxes',
            description: 'Manage Boxes.',
            type: 'link',
            icon: 'i-Windows-2',
            state: '/boxes'
        },
        {
            name: 'Media Gallery',
            description: 'View Media Gallery.',
            type: 'link',
            icon: 'i-Picasa',
            state: '/media'
        },
        {
            name: 'Profile',
            description: 'Manage Boxes.',
            type: 'link',
            icon: 'i-Administrator',
            state: '/profile'
        },
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.userMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string) {
        console.log(menuType);
      switch (menuType) {
        case 'admin':
          this.menuItems.next(this.adminMenu);
          break;
        case 'user':
          this.menuItems.next(this.userMenu);
          break;
        default:
          this.menuItems.next(this.defaultMenu);
      }
    }
}
