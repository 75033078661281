import { Injectable } from "@angular/core";
declare var $: any;
declare var jQuery: any;
import * as _ from "lodash";

@Injectable()
export class Configuration {

    // public serverUrl = "http://localhost/NDO/api/public/index.php/api/";
    // public uploadUrl = "http://localhost/NDO/api/public/uploads/";

    // public serverUrl = "https://ts.wtshub.com/nexday-overnite/api/public/api/";
    // public uploadUrl = "https://ts.wtshub.com/nexday-overnite/api/public/uploads/";

    // public serverUrl = "http://wts.wtshub.com/nexday-overnite/api/public/api/";
    // public uploadUrl = "http://wts.wtshub.com/nexday-overnite/api/public/uploads/";

    // public serverUrl = "http://localhost/api/public/index.php/api/";
     //public uploadUrl = "http://localhost/api/public/uploads/";


      public serverUrl = "https://click2store.co.uk/api/public/index.php/api/";
      public uploadUrl = "https://click2store.co.uk/api/public/uploads/";

    public itemsPerPage: number = 5;
    public maxSize: number = 5;
    accessReturn: boolean = false;
    permissions = [];

    public boxStatuses = {
        1: 'Active',
        0: 'Inactive',
    }
    
    checkAccess(entity, access, baipass, checkProject = "") {
        let checkMore = true;
        if (baipass != undefined && Object.keys(baipass).length > 0) {
          let checkAcc = false;
          _.forEach(baipass, function(value) {
            if (value == localStorage.getItem("role")) {
              checkAcc = true;
            }
          });
    
          if (!checkAcc) {
            checkMore = true;
          } else {
            checkMore = false;
          }
        }
    
        if (checkMore) {
          this.accessReturn = false;
          let that = this;

            if (localStorage.getItem("permissions") != "undefined") {
              this.permissions = JSON.parse(localStorage.getItem("permissions"));
            }
          
          _.forEach(this.permissions, function(value) {
            // console.log(entity.substring(1));
            // console.log(value.entity.slug);
            if (value.entity.slug == entity.substring(1)) {
              if (value[access + "_data"]) {
                that.accessReturn = true;
                // console.log(value[access + "_data"]);
              }
            }
          });
          // console.log(this.accessReturn);
          return this.accessReturn;
        } else {
          // console.log(this.accessReturn);
          return true;
        }
        
      }
}
